<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmUserBillDetailsFormData && Object.keys(vmUserBillDetailsFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationbill_name">
                    {{cvBillNameLabel}}</label
                  >
                  <input
                    v-model="vmUserBillDetailsFormData.bill_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationbill_address">
                    {{cvBillAddressLabel}}</label
                  >
                  <textarea v-model="vmUserBillDetailsFormData.bill_address" type="text" class="form-control"
                  ></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationbill_city">
                    {{cvBillCityLabel}}</label
                  >
                  <input
                    v-model="vmUserBillDetailsFormData.bill_city"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationbill_state">
                    {{cvBillStateLabel}}</label
                  >
                  <input
                    v-model="vmUserBillDetailsFormData.bill_state"
                    type="text"
                    class="form-control"
                    required
                    />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationbill_country">
                    {{cvBillCountryLabel}}</label
                  >
                  <input
                    v-model="vmUserBillDetailsFormData.bill_country"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationbill_zip_code">
                    {{cvBillZipCodeLabel}}</label
                  >
                  <input
                    v-model="vmUserBillDetailsFormData.bill_zip_code"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="userbilldetailsEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { UserBillDetails } from "../../../FackApi/api/UserBillDetails"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "UserBillDetailsEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserBillDetailsObj: {
      type: Object,
      default: function () {
        return {
          "bill_name": "",
          "bill_city": "",
          "bill_zip_code": "",
          "bill_state": "",
          "bill_country": "",
          "bill_address": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "User Details",
      cvCardSubHeader: "User Details",
      cvSubmitBtn: "Edit",
      cvBillNameLabel: "Name",
      cvBillCityLabel: "City",
      cvBillZipCodeLabel: "Zip code",
      cvBillStateLabel: "State",
      cvBillCountryLabel: "Country",
      cvBillAddressLabel: "Address",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserBillDetails Updated",
      vmUserBillDetailsFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.userbilldetailsView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserBillDetailsFormData) {
          if (!this.vmUserBillDetailsFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * userbilldetailsView
     */
    async userbilldetailsView () {
      try {
        if (this.propOpenedInModal) {
          this.vmUserBillDetailsFormData = this.propUserBillDetailsObj
        }
        else {
          let userbilldetailsId = this.$route.params.ubd_id
          let userbilldetailsViewResp = await UserBillDetails.userbilldetailsView(this, userbilldetailsId)

          if (userbilldetailsViewResp && userbilldetailsViewResp.resp_status) {
            this.vmUserBillDetailsFormData = userbilldetailsViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsView() and Exception:", err.message)
      }
    },
    /**
     * userbilldetailsEdit
     */
    async userbilldetailsEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let userbilldetailsAddResp = await UserBillDetails.UserBillDetailsEdit(this, this.vmUserBillDetailsFormData)
        await ApiResponse.responseMessageDisplay(this, userbilldetailsAddResp)

        if (userbilldetailsAddResp && !userbilldetailsAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUserBillDetailsEditModal", this.vmUserBillDetailsFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style>
textarea {
  min-height: 150px !important;
  line-height: 27px !important;
}
</style>
